import { Ref, useEffect, useRef, useState } from 'react';

type UseVisibilityTriggerOptions = {
  threshold?: number;
  alwaysObserve?: boolean;
};

type UseVisibilityTriggerReturn = {
  isVisible: boolean;
  elementRef: Ref<HTMLDivElement> | undefined;
};

const useVisibilityTrigger = ({
  threshold = 0.1,
  alwaysObserve = false
}: UseVisibilityTriggerOptions): UseVisibilityTriggerReturn => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<null | HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (!alwaysObserve) {
            observer.unobserve(entry.target); // Stop observing if alwaysObserve is false
          }
        } else if (alwaysObserve) {
          setIsVisible(false); // Reset visibility if alwaysObserve is true
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [threshold, alwaysObserve]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return { isVisible, elementRef };
};

export default useVisibilityTrigger;
