import { faqs } from './constants';
import { Column } from '@/components/Column';
import classNames from 'classnames';
import React from 'react';
import EllipsisCircle from '../EllipsisCircle';
import { useScreenSize } from '@/hooks';
import { trackGenericEvent } from '@/utils/analytics';

const FAQs: React.FC = () => {
  const [open, setOpen] = React.useState<number | undefined>(0);
  const { isMobile } = useScreenSize();
  const handleOpen = (index: number) => {
    if (open === index) {
      setOpen(undefined);
    } else {
      setOpen(index);
    }
  };

  return (
    <Column as="section" className="justify-center items-center py-20 relative" gap={32}>
      <EllipsisCircle
        color="#0072FF"
        size={isMobile ? 150 : 300}
        rotation={23.717}
        opacity={0.1}
        blur={40}
        className="absolute bottom-0 left-0"
      />
      <EllipsisCircle
        color="#FF4658"
        size={isMobile ? 150 : 300}
        rotation={23.717}
        opacity={0.1}
        blur={40}
        className="absolute top-20 right-0"
      />

      <h2 className="text-xl sm:text-4xl font-medium">Encontrá las respuestas que buscás</h2>
      <Column className="w-full max-w-[700px]" gap={16} align="center">
        {faqs.map((faq, index) => (
          <div
            className={classNames('collapse collapse-arrow bg-white rounded-lg w-full', {
              'shadow-[0px_8px_16px_0px_#21212133] border border-[#E9E9E9]': index === open
            })}
            key={index}
          >
            <input
              type="checkbox"
              id={index.toString()}
              className="collapse-toggle"
              checked={index === open}
              onChange={() => {
                handleOpen(index);
                trackGenericEvent('Button FAQs Landing Page Clicked', {
                  question: faq.title,
                  answer: faq.content
                });
              }}
            />
            <div
              className={classNames('collapse-title text-sm sm:text-xl flex items-center', {
                'text-[#0072FF] font-medium': index === open
              })}
            >
              {faq.title}
            </div>
            <div className="collapse-content">
              <div className="text-sm">{faq.content}</div>
            </div>
          </div>
        ))}
      </Column>
    </Column>
  );
};

export default FAQs;
