import { CurrencyE, PaymentI, SupportedCountriesE } from '@/types/cyclone/models';

export function formatCurrencyWithLocale(number: number): string {
  const formattedNumber = number.toLocaleString('es-AR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
  return `$${formattedNumber}`;
}

export const formatCurrency = (value: number, _country?: SupportedCountriesE, isUsd?: boolean): string => {
  let locale: string;
  let currencySymbol: string;

  const country = _country || SupportedCountriesE.ARGENTINA;

  // Determine the locale based on the country
  switch (country) {
    case SupportedCountriesE.ARGENTINA:
      locale = 'es-AR';
      currencySymbol = isUsd ? 'US$' : 'AR$';
      break;
    case SupportedCountriesE.URUGUAY:
      locale = 'es-UY';
      currencySymbol = isUsd ? 'US$' : 'UY$';
      break;
    case SupportedCountriesE.CHILE:
      locale = 'es-CL';
      currencySymbol = isUsd ? 'US$' : 'CL$';
      break;
    default:
      locale = 'es-AR';
      currencySymbol = 'AR$'; // Default case, assuming Argentina for simplicity
  }

  // Format the number with Intl.NumberFormat to ensure correct locale-specific number formatting
  const formattedNumber = new Intl.NumberFormat(locale, {
    minimumFractionDigits: isUsd ? 2 : 0,
    maximumFractionDigits: isUsd ? 2 : 0
  }).format(value);

  // Concatenate the currency symbol with the formatted number
  return `${currencySymbol}${formattedNumber}`;
};

export const formatCurrencyFromCoin = (value: number, currency = CurrencyE.ARS): string => {
  let locale: string;
  let currencySymbol: string;

  switch (currency) {
    case CurrencyE.ARS:
      locale = 'es-AR';
      currencySymbol = 'AR$';
      break;
    case CurrencyE.USD:
      locale = 'en-US';
      currencySymbol = 'US$';
      break;
    case CurrencyE.UYU:
      locale = 'es-UY';
      currencySymbol = 'UY$';
      break;
    case CurrencyE.CLP:
      locale = 'es-CL';
      currencySymbol = 'CL$';
      break;
    default:
      locale = 'es-AR'; // Default to Argentine Peso settings
      currencySymbol = 'AR$';
      break;
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const formattedValue = formatter.format(value);
  const valueWithoutCurrencyCode = formattedValue.replace(/[^\d,.-]/g, '');

  return `$${valueWithoutCurrencyCode}`;
};

export const formatCurrencyNotRegion = (value: number, type?: CurrencyE): string => {
  const formatType = type === CurrencyE.USD ? 'en-US' : 'es-AR';
  const currency = (type && type.toUpperCase()) || CurrencyE.ARS.toUpperCase();
  const maximumFractionDigits = type === CurrencyE.USD ? 2 : 0;

  const validatedMaximumFractionDigits = Math.min(Math.max(maximumFractionDigits, 0), 2);

  const formattedValue = new Intl.NumberFormat(formatType, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: validatedMaximumFractionDigits
  }).format(value);

  return formattedValue;
};

export const formatNumberWithCommas = (value: number) => {
  const parts = value.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return parts.join(',');
};

export const getPaymentPrice = (payments: PaymentI[]) => {
  const price = (payments && payments[0]?.amount) || 0;
  return formatCurrencyFromCoin(price, payments[0].currency);
};
