import { trackGenericEvent } from '@/utils/analytics';
import EllipsisCircle from '../EllipsisCircle';
import { ImagesAnimation } from './ImagesAnimation';
import { ItemType, items } from './constants';
import { useScreenSize } from '@/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { Row } from '@/components/Row';
import { Column } from '@/components/Column';
import { GradientButton } from '@/components/GradientButton';

// extend ItemType to add isSelected
type ItemTypeExtended = ItemType & {
  isSelected?: boolean;
  withLine?: boolean;
  itemSelected: number;
  onToggle: () => void;
};
const Item: FunctionComponent<ItemTypeExtended> = ({
  title,
  content,
  icon,
  isSelected = false,
  withLine = false,
  itemSelected,
  onToggle // Add this prop
}) => {
  const { isMobile } = useScreenSize();

  return isMobile ? (
    <div className="collapse collapse-arrow">
      <input
        type="checkbox"
        checked={isSelected}
        className="peer"
        onChange={onToggle} // Add the onChange handler here
      />
      <div className="collapse-title">
        <Row align="center" gap={10} className="w-full">
          <span className="flex items-center justify-center rounded-full p-2 w-[40px] h-[40px] text-blue-500 bg-[#CCE3FF]">
            <FontAwesomeIcon icon={icon} width={22} height={22} color={'#0072FF'} />
          </span>
          <h3
            className={classNames({
              'md:text-xl font-bold': true,
              'text-blue': isSelected
            })}
          >
            {title}
          </h3>
        </Row>
      </div>
      <div className="collapse-content">
        <p className="text-sm md:text-base">{content}</p>
        <Column className="relative max-w-full w-full aspect-square" align="center" justify="center">
          <ImagesAnimation key={itemSelected} index={itemSelected} isSelected={isSelected} />
        </Column>
      </div>
    </div>
  ) : (
    <Row
      gap={16}
      className={classNames({
        'opacity-50': !isSelected,
        'border-b-[1px]': !withLine,
        'border-blue': isSelected,
        'transition-all duration-300 ease-in-out mb-4 pb-4': true
      })}
    >
      <Column>
        <span className="flex items-center justify-center rounded-full p-1 w-[56px] h-[56px] text-blue-500 bg-[#E7F2FB]">
          <FontAwesomeIcon icon={icon} size="lg" color={'#0072FF'} />
        </span>
      </Column>
      <Column gap={8}>
        <h3
          className={classNames({
            'md:text-xl font-bold': true,
            'text-blue': isSelected
          })}
        >
          {title}
        </h3>
        <p className="text-sm md:text-base">{content}</p>
      </Column>
    </Row>
  );
};

interface Props {
  onCTA: (section: string) => void;
}

const StoreSteps: FunctionComponent<Props> = ({ onCTA }) => {
  const [itemSelected, setItemSelected] = React.useState(0);
  const { isMobile } = useScreenSize();
  const sectionRefs = React.useRef<Array<HTMLElement | null>>([]);

  const smoothScrollToSection = (sectionId: string) => {
    const sectionIndex = parseInt(sectionId.replace('section', ''), 10) - 1;
    const sectionRef = sectionRefs.current[sectionIndex];

    if (!sectionRef) return;

    const scrollToPosition = sectionRef.getBoundingClientRect().top + window.scrollY;
    window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
  };

  return (
    <Column className="relative py-14 md:py-20" gap={isMobile ? 32 : 44}>
      <EllipsisCircle
        color="#0072FF"
        size={isMobile ? 300 : 700}
        rotation={23.717}
        opacity={0.1}
        blur={50}
        className="absolute top-44 right-0 lg:-bottom-44 lg:-right-44 lg:top-auto"
      />
      <Row className="text-2xl md:text-[40px] md:leading-tight font-bold">
        <h2>
          Empezar a vender online
          <br />
          ahora es más simple
        </h2>
      </Row>
      <Row gap={40}>
        <Column style={{ flex: 5 }} gap={24}>
          <Column gap={16}>
            <Column gap={16}>
              {items.map((item, index) => (
                <div
                  key={index}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ref={(el) => (sectionRefs.current[index] = el)}
                  onClick={() => {
                    setItemSelected(index);
                    if (isMobile) smoothScrollToSection(`section${index}`);
                    trackGenericEvent('Button Landing Page Clicked', {
                      section: 'Store Steps',
                      step: index + 1
                    });
                  }}
                  className="cursor-pointer"
                >
                  <Item
                    {...item}
                    itemSelected={itemSelected}
                    isSelected={itemSelected === index}
                    onToggle={() => setItemSelected(index)} // Add this prop
                  />
                </div>
              ))}
            </Column>
          </Column>
        </Column>
        {!isMobile && (
          <Column style={{ flex: 5 }} className="relative" align="center" justify="center">
            <ImagesAnimation key={itemSelected} index={itemSelected} />
          </Column>
        )}
      </Row>
      <div>
        <GradientButton onClick={() => onCTA('Store Steps')}>Crear mi tienda</GradientButton>
      </div>
    </Column>
  );
};

export default StoreSteps;
