import EllipsisCircle from '../EllipsisCircle';
import { useScreenSize } from '@/hooks';
import useVisibilityTrigger from '@/hooks/useVisibilityTrigger';
import { faBell, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import Image from 'next/legacy/image';
import React, { FunctionComponent } from 'react';
import { GradientButton } from '@/components/GradientButton';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useCountryContext } from '@/contexts/country';
import { getPricesByCountry } from '@/utils/plans';
import { getSupportedCountriesFromCode } from '@/utils/geo';
import { formatNumberWithCommas } from '@/utils/money';

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

interface Props {
  onCTA: (section: string) => void;
}
const Pricing: FunctionComponent<Props> = ({ onCTA }) => {
  const { isMobile } = useScreenSize();
  const { country } = useCountryContext();
  const { isVisible, elementRef } = useVisibilityTrigger({
    threshold: 0.5
  });

  const { professional_base_price } = getPricesByCountry(getSupportedCountriesFromCode(country as any));

  console.log('🚀 ~ isVisible:', isVisible);

  return (
    <div
      className={`flex flex-col lg:flex-row justify-center items-center text-black py-14 md:py-20 gap-16 relative`}
    >
      <EllipsisCircle
        color="#0072FF"
        size={400}
        rotation={23.717}
        opacity={0.1}
        blur={40}
        className="absolute -top-40 -right-80"
      />
      <Column style={{ flex: isMobile ? 'none' : 6 }} className={`${isMobile ? 'order-1' : ''} gap-4`}>
        <h2 className="text-2xl md:text-[40px] font-bold leading-snug">
          Creá tu tienda en Ágora <br /> por sólo{' '}
          <span
            style={{
              background: 'linear-gradient(148deg, #FF4658 20%, #0072FF 80%, #0AD6A1 99%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            ${formatNumberWithCommas(professional_base_price)} mensuales
          </span>
        </h2>
        <p>
          Incluye todas las herramientas para automatizar reservas, cobros,
          <br /> recordatorios y cancelaciones.
        </p>
        <p className="pt-1">
          Antes de comenzar a pagar, <b>tenés 14 días de prueba gratis.</b>
        </p>
        <div className="pt-8">
          <GradientButton onClick={() => onCTA('Pricing')}>Crear mi tienda</GradientButton>
        </div>
      </Column>
      <Column style={{ flex: isMobile ? 'none' : 4 }} className={`${isMobile ? 'order-2' : ''} relative`}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isVisible ? 1 : 0, x: 100 }}
          transition={{ duration: 0.3, ease: 'easeIn', delay: 0.3 }}
        >
          <Row
            gap={8}
            align="center"
            className="z-10 px-[10px] md:px-4 py-1 md:py-2 rounded-lg absolute bg-white -left-32 sm:-left-40 sm:top-20 top-5"
            style={{
              boxShadow: '10px 10px 27px 0px #C4CAD3'
            }}
          >
            <span className="flex items-center justify-center rounded-full p-1 w-[25px] h-[25px] md:w-[40px] md:h-[40px] text-blue-500 bg-[#E7F2FB]">
              <FontAwesomeIcon icon={faCalendar} size={isMobile ? 'sm' : 'xl'} color={'#0072FF'} />
            </span>
            <p className="text-xs md:text-sm">Juan reagendó su turno</p>
          </Row>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: isVisible ? 1 : 0, x: 0 }}
          transition={{ duration: 0.3, ease: 'easeIn', delay: 0.3 }}
          style={{ position: 'absolute', right: 0, bottom: 80, width: '100%' }}
        >
          <Row
            gap={8}
            align="center"
            className="z-10 px-[10px] md:px-4 py-1 md:py-2 rounded-lg absolute bg-white -right-8 sm:-right-0 bottom-20"
            style={{
              boxShadow: '10px 10px 27px 0px #C4CAD3'
            }}
          >
            <span className="flex items-center justify-center rounded-full p-1 w-[25px] h-[25px] md:w-[40px] md:h-[40px] text-blue-500 bg-[#FFA0AA80]">
              <FontAwesomeIcon icon={faBell} size={isMobile ? 'sm' : 'xl'} color={'#FF4658'} />
            </span>
            <p className="text-xs md:text-sm">Tu sesion comienza en 30m</p>
          </Row>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isVisible ? 1 : 0, x: 100 }}
          transition={{ duration: 0.3, ease: 'easeIn', delay: 0.3 }}
        >
          <Row
            gap={8}
            align="center"
            className="z-10 px-[10px] md:px-4 py-1 md:py-2 rounded-lg absolute bg-white -left-32 sm:-left-40 top-56 sm:top-80"
            style={{
              boxShadow: '10px 10px 27px 0px #C4CAD3'
            }}
          >
            <span className="flex items-center justify-center rounded-full p-1 w-[25px] h-[25px] md:w-[40px] md:h-[40px] text-blue-500 bg-[#E7F2FB]">
              <FontAwesomeIcon icon={faDollarSign} size={isMobile ? 'sm' : 'xl'} color={'#0072FF'} />
            </span>
            <p className="text-xs md:text-sm">Recibiste un pago de $7.500</p>
          </Row>
        </motion.div>
        <motion.div
          ref={elementRef}
          initial="hidden"
          animate={isVisible ? 'visible' : 'hidden'}
          variants={{
            hidden: { scale: 0.4, opacity: 0 },
            visible: { scale: 1, opacity: 1 }
          }}
          transition={{ duration: 0.3 }}
        >
          <Image
            alt="agora-vendor"
            src={`${IMAGE_CDN_URL}/landing/agora-vendor.png` || ''}
            width={isMobile ? 290 : 390}
            height={isMobile ? 330 : 420}
            loading="lazy"
            className="-z-10"
          />
        </motion.div>
      </Column>
    </div>
  );
};

export default Pricing;
