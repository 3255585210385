import { trackGenericEvent } from '@/utils/analytics';
import EllipsisCircle from '../EllipsisCircle';
import { ItemType, items } from './constants';
import { useScreenSize } from '@/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { Grid } from '@/components/Grid';

// ! USE ARBORIA BOOK FONT
const Item: FunctionComponent<ItemType> = ({ title, content, icon }) => {
  const { isMobile } = useScreenSize();
  return (
    <Column gap={16} className="p-4 rounded-lg bg-white/60">
      <Row align="center" gap={8}>
        <span className="flex items-center justify-center rounded-full p-1 w-[40px] h-[40px] text-blue-500 bg-[#E7F2FB]">
          <FontAwesomeIcon icon={icon} width={24} height={24} color={'#0072FF'} />
        </span>
        <h3 className="text-lg md:text-xl font-bold">{title}</h3>
      </Row>
      <p className="pb-0 sm:pb-4">{content}</p>
    </Column>
  );
};

const Tools: FunctionComponent = () => {
  const { isMobile } = useScreenSize();
  return (
    <div className="relative">
      {!isMobile && (
        <EllipsisCircle
          color="#FF4658"
          size={isMobile ? 300 : 400}
          rotation={23.717}
          opacity={0.1}
          blur={120}
          className="absolute -top-44 lg:-left-20 lg:bottom-10"
        />
      )}
      <EllipsisCircle
        color="#0072FF"
        size={isMobile ? 400 : 600}
        rotation={23.717}
        opacity={0.2}
        blur={isMobile ? 20 : 160}
        className="absolute top-60 md:-bottom-56 -left-40 md:left-0"
      />
      {isMobile && (
        <EllipsisCircle
          color="#FF4658"
          size={250}
          rotation={23.717}
          opacity={0.2}
          blur={40}
          className="absolute top-[500px] -left-20"
        />
      )}
      <EllipsisCircle
        color="#FF4658"
        size={isMobile ? 300 : 500}
        rotation={23.717}
        opacity={0.2}
        blur={isMobile ? 100 : 80}
        className="absolute md:top-20 -right-20 lg:-right-44 lg:-bottom-[200px] lg:top-auto"
      />
      {isMobile && (
        <EllipsisCircle
          color="#0072FF"
          size={400}
          rotation={23.717}
          opacity={0.1}
          blur={20}
          className="absolute bottom-0 -right-20"
        />
      )}
      <Column className="pt-8 pb-14 md:py-20" align="center" gap={isMobile ? 32 : 40}>
        <Row className="text-2xl md:text-[40px] font-bold md:text-center leading-snug">
          <h2>Mientras vos descansás, {!isMobile && <br />} Ágora potencia tu negocio las 24hs.</h2>
        </Row>
        {isMobile ? (
          <Column gap={20}>
            {items.map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </Column>
        ) : (
          <>
            <Grid as="div" className="grid-flow-row grid-cols-3" gap={24}>
              {items.slice(0, 3).map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </Grid>
            <Grid as="div" className="grid-flow-row grid-cols-3" gap={24}>
              {items.slice(3, 6).map((item, index) => (
                <Item key={index} {...item} />
              ))}
            </Grid>
          </>
        )}
        <Row>
          <Link
            onClick={() => trackGenericEvent('Button Landing Page Clicked', { section: 'Functionality' })}
            href="/funcionalidades"
            className="text-blue text-sm lg:text-lg underline"
          >
            Conocer todas las funcionalidades
          </Link>
        </Row>
      </Column>
    </div>
  );
};

export default Tools;
