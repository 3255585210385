import React from 'react';
import { icons } from './constants';
import Image from 'next/legacy/image';

export const IntegrationsMarquee: React.FC = () => {
  const extendedIcons = [...icons, ...icons];

  return (
    <div className="marquee-container">
      <div className="marquee-track">
        {extendedIcons.map((icon, index) => (
          <div key={index} className="marquee-logo">
            <Image
              src={icon.src}
              width={icon.width}
              height={icon.height}
              alt={icon.alt}
              objectFit="contain"
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
