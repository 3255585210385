import { trackGenericEvent } from '@/utils/analytics';
import EllipsisCircle from '../EllipsisCircle';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from '@/components/Dialog';
import { useScreenSize } from '@/hooks';
import { commonImageProps } from '@/utils/images';
import { faPlay, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/legacy/image';
import React, { FunctionComponent, useRef, useState } from 'react';

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

const Why: FunctionComponent = () => {
  const { isMobile } = useScreenSize();

  const portraitVideo = `${IMAGE_CDN_URL}/landing/agora_landing_portrait.png`;
  const video = `${IMAGE_CDN_URL}/landing/agora_landing_video.mp4`;

  return (
    <Row className="relative justify-center items-center text-black pb-14 md:py-20" gap={isMobile ? 0 : 64}>
      {!isMobile && (
        <EllipsisCircle
          color="#0072FF"
          size={300}
          rotation={23.717}
          opacity={0.3}
          blur={100}
          className="absolute md:-bottom-20 md:-left-20"
        />
      )}
      <div className="flex flex-col-reverse lg:flex-row justify-center items-center md:gap-16">
        <Dialog>
          <DialogTrigger
            onClick={() => trackGenericEvent('Button Landing Page Clicked', { section: 'Video' })}
          >
            <div className=" w-[300px] md:w-[500px] xl:w-[550px]  aspect-video relative mt-8 md:pt-0">
              <div className="video-play-button z-20 cursor-pointer">
                <FontAwesomeIcon
                  icon={faPlayCircle}
                  className="play-button absolute text-white text-5xl z-20"
                  style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
                />
              </div>
              <Image
                {...commonImageProps}
                src={portraitVideo}
                alt={'Agora'}
                layout="fill"
                className="bg-cover bg-slate-300 bg-no-repeat bg-center z-0 absolute"
              />
            </div>
          </DialogTrigger>
          <DialogContent className="flex items-start xl:max-w-5xl w-full border-none">
            <video src={video} controls autoPlay className="w-full sm:rounded-lg" />
          </DialogContent>
        </Dialog>
        <Column className="lg:w-1/2 md:ml-4 mb-4 md:mb-0 md:order-2" gap={isMobile ? 16 : 24}>
          <h2 className="text-2xl md:text-[40px] font-black leading-snug">
            Una solución integral <br /> para tu emprendimiento
          </h2>
          <h4 className="text-base md:text-2xl">
            Desde tu computadora o celular, gestioná tu negocio, rápido y fácil. Todas tus herramientas, en un
            sólo lugar.
          </h4>
        </Column>
      </div>
    </Row>
  );
};

export default Why;
