type LandingFaq = {
  title: string;
  content: JSX.Element | string;
};

export const faqs: LandingFaq[] = [
  {
    title: '¿Qué es Ágora?',
    content: (
      <>
        <p>Somos una solución integral para gestionar y escalar un negocio de servicios.</p>
        <p>
          Equipamos profesionales independientes con herramientas digitales para potenciar sus
          emprendimientos.
        </p>
      </>
    )
  },
  {
    title: '¿Cómo funciona?',
    content: (
      <>
        <p>1. Creás tu negocio en Ágora (tu propia tienda digital) en 5 minutos</p>
        <p>2. Construís tu catálogo de servicios presenciales u online</p>
        <p>3. Definís tu disponibilidad y la de tus distintos servicios</p>
        <p>¡Listo! Tus clientes ya pueden contratarte sin intermediarios.</p>
      </>
    )
  },
  {
    title: '¿Qué podés vender con Ágora?',
    content: (
      <>
        <p>Nuestras herramientas para equipar tu negocio:</p>
        <p>• Venta de tickets para eventos</p>
        <p>• Reservas para clases/sesiones grupales</p>
        <p>• Sistema de turnos bajo demanda para clases/sesiones personalizadas</p>
        <p>• Venta por pack (descuento por compra anticipada de 4/8 encuentros)</p>
        <p>• Planes mensuales a tus servicios y contenido digital, con débito automático</p>
        <p>
          • {'"'}Bancar{'"'}: aportes directos de tus seguidores y fans
        </p>
      </>
    )
  },
  {
    title: '¿Cómo recibís los pagos de tus clientes?',
    content: (
      <>
        <p>Con solo asociar tu cuenta de Mercado Pago, ya podrás cobrar desde tu negocio en Ágora.</p>
        <p>Todos los cobros se acreditarán directamente en tu billetera de Mercado Pago.</p>
      </>
    )
  },
  {
    title: '¿Ágora cobra comisión sobre mis ventas?',
    content: (
      <>
        <p>No, no cobramos ninguna comisión sobre tus ventas.</p>
        <p>
          Mercado Pago aplicará una comisión por procesamiento de pago, sujeta al plazo de acreditación
          indicado.{' '}
          <a
            href="https://www.mercadopago.com.ar/costs-section/release-options/edit/merchant-services"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ver más detalle.
          </a>
        </p>
      </>
    )
  },
  {
    title: '¿Cómo te encuentran tus clientes?',
    content: (
      <>
        <p>Al crear tu negocio, podrás definir el link a tu página web.</p>
        <p>Podrás enviar el mismo a tus clientes o compartirlo en tu bio de redes sociales.</p>
        <p>
          La idea es que vendas TODO desde un solo lugar, con tu disponibilidad y condiciones actualizadas en
          tiempo real.
        </p>
      </>
    )
  }
];
