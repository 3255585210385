import { trackGenericEvent } from '@/utils/analytics';
import EllipsisCircle from '../EllipsisCircle';
import TextChanger from '../TextChanger';
import { ProfessionalType, professionals } from './constants';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import Image from 'next/legacy/image';
import React, { FunctionComponent } from 'react';

const Professional: FunctionComponent<ProfessionalType> = ({ name, title, image, url }) => {
  return (
    <Column
      className="rounded-md bg-white cursor-pointer w-[190px] h-[240px] card-container"
      onClick={() => {
        window.open(url, '_blank', 'noopener,noreferrer');
      }}
    >
      <Row className="card-image-container overflow-hidden">
        <Image
          src={image}
          objectFit="cover"
          layout="fill"
          objectPosition="top"
          className="absolute rounded-t-md"
          alt={name}
          loading="lazy"
        />
        <span className="store-link absolute top-2/4 left-1/4 text-white underline font-bold hidden">
          Visitar tienda
        </span>
      </Row>
      <Column className="pt-2 px-4 pb-4">
        <h3 className="text-sm md:text-base truncate">{name}</h3>
        <p className="text-sm md:text-base font-bold">{title}</p>
      </Column>
    </Column>
  );
};

const Professionals: FunctionComponent = () => {
  const { isMobile } = useScreenSize();
  return (
    <Column className="relative py-14 md:py-20" gap={40} align="center" justify="center">
      <EllipsisCircle
        color="#FF4658"
        size={450}
        rotation={23.717}
        opacity={isMobile ? 0.2 : 0.1}
        blur={50}
        className="absolute -bottom-40 -right-56 md:right-80"
      />
      <EllipsisCircle
        color="#0072FF"
        size={350}
        rotation={23.717}
        opacity={0.15}
        blur={40}
        className="absolute top-40 md:top-20 -left-52 md:-left-40"
      />
      <Row justify="center">
        <h2 className="text-xl md:text-[40px] font-bold leading-snug">
          Cientos de negocios ya eligen Ágora <br /> para vender sus{' '}
          <span>
            <TextChanger
              gradient
              from="#0072FF"
              to="#0AD6A1"
              words={['turnos', 'sesiones', 'capacitaciones', 'descargables']}
            />
          </span>
        </h2>
      </Row>
      {isMobile ? (
        <div className="flex gap-5 overflow-x-auto no-scrollbar w-full">
          {professionals.map((professional, index) => (
            <div
              key={index}
              onClick={() =>
                trackGenericEvent('Button Landing Page Clicked', {
                  section: 'Merchants',
                  merchant: professional.name
                })
              }
            >
              <Professional {...professional} />
            </div>
          ))}
        </div>
      ) : (
        <Row gap={24}>
          {professionals.map((professional, index) => (
            <div
              key={index}
              onClick={() =>
                trackGenericEvent('Button Landing Page Clicked', {
                  section: 'Merchants',
                  merchant: professional.name
                })
              }
            >
              <Professional {...professional} />
            </div>
          ))}
        </Row>
      )}
    </Column>
  );
};

export default Professionals;
