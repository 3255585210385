import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons';
import { faLaptop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';

// ! USE ARBORIA BOOK FONT
const Support: FunctionComponent = () => {
  const { isMobile } = useScreenSize();
  return (
    <section className="flex flex-col py-14 w-full gap-6 sm:flex-row sm:justify-center sm:items-center sm:py-20 sm:mb-10 sm:gap-16">
      <Column gap={16} className="flex-1">
        <h2 className="text-2xl sm:text-4xl ml-3 font-bold">
          Estamos para ayudarte <br /> y crecer en comunidad
        </h2>
        <h4 className="text-base sm:text-xl ml-3">Te respondemos personas, no robots. </h4>
      </Column>
      <Column gap={8} className="flex-1">
        <Row gap={16} align="center" className="p-4 border-b-2 border-b-gray-300">
          <span className="flex items-center justify-center rounded-full sm:w-[40px] w-[80px] aspect-square bg-[#CCE3FF]">
            <FontAwesomeIcon icon={faWhatsapp} size={isMobile ? '2xl' : 'xl'} color="#0072FF" />
          </span>
          <p>Respondemos por WhatsApp para que nada te detenga.</p>
        </Row>
        <Row gap={16} align="center" className="p-4 border-b-2 border-b-gray-300">
          <span className="flex items-center justify-center rounded-full sm:w-[40px] w-[80px] aspect-square bg-[#FFEDEE]">
            <FontAwesomeIcon icon={faLaptop} size="xl" color="#FF4658" />
          </span>
          <p>Organizamos workshops para compartir aprendizajes.</p>
        </Row>
        <Row gap={16} align="center" className="p-4">
          <span className="flex items-center justify-center rounded-full sm:w-[40px] w-[80px] aspect-square bg-[#E7FBF6]">
            <FontAwesomeIcon icon={faFaceSmile} size={isMobile ? '2xl' : 'xl'} color="#0AD6A1" />
          </span>
          <p>Escuchamos tus necesidades para mejorar tu experiencia.</p>
        </Row>
      </Column>
    </section>
  );
};

export default Support;
