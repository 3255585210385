export const professionals = [
  {
    name: 'Akira Studio',
    title: 'Estudio de Pestañas',
    image: `https://d3p2i4pt6coq3o.cloudfront.net/users/23690/avatar_1725587697.webp`,
    url: '/akirastudio'
  },
  {
    name: 'Noel Villan',
    title: 'Centro de Estética',
    image: `https://d3p2i4pt6coq3o.cloudfront.net/users/26740/avatar_1710006803.webp`,
    url: '/nvstudio'
  },
  {
    name: 'Marea Estudio',
    title: 'Centro de Depilación',
    image: `https://d3p2i4pt6coq3o.cloudfront.net/users/32622/avatar_1723123951.webp`,
    url: '/mareaestudio'
  },
  {
    name: 'Pulgar Barbería',
    title: 'Barbería',
    image: `https://d3p2i4pt6coq3o.cloudfront.net/users/54064/avatar_1725679182.webp`,
    url: '/pulgarbarberia'
  },
  {
    name: 'Browlash ',
    title: 'Estudio de Cejas',
    image: `https://d3p2i4pt6coq3o.cloudfront.net/users/14941/avatar_1711662706.webp`,
    url: '/browlash.arg'
  }
];

export type ProfessionalType = (typeof professionals)[number];
