import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faDollarSign, faTicket } from '@fortawesome/free-solid-svg-icons';

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

export const firstColumnVendors = [
  {
    name: 'Facundo Vindis',
    title: 'Academia de cejas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_14.webp`
  },
  {
    name: 'Guillermina Ferrá',
    title: 'Lic. en Psicopedagogía',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_1.webp`
  },
  {
    name: 'Celeste Sotelo',
    title: 'Academia de cejas y pestañas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_8.webp`
  },
  {
    name: 'Francisco Muñoz',
    title: 'Coach y guia de montaña',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_16.webp`
  },
  {
    name: 'Nayme Fuentes',
    title: 'Estudio de belleza',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_11.webp`
  }
];

export const secondColumnVendors = [
  {
    name: 'Las Mil Dias',
    title: 'Nutricionistas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_2.webp`
  },
  {
    name: 'Nashyra Esprella',
    title: 'Lami trainer internacional',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_5.webp`
  },
  {
    name: 'Sinner Studio',
    title: 'Centro de belleza',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_7.webp`
  },
  {
    name: 'Juli Te Cuenta',
    title: 'Astrología y tarot',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_15.webp`
  },
  {
    name: 'Cona Studio',
    title: 'Estudio de cejas y pestañas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_10.webp`
  }
];

export const items = [
  {
    text: 'Juan reagendó su turno',
    icon: faCalendarAlt,
    color: 'blue'
  },
  {
    text: 'Recibiste un pago en USD',
    icon: faDollarSign,
    color: 'green'
  },
  {
    text: '¡Felicitaciones! Vendiste todas las entradas.',
    icon: faTicket,
    color: 'blue'
  }
];

export type ProfessionalType = (typeof firstColumnVendors)[number];
export type ItemType = (typeof items)[number];
