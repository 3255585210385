'use client';

import { useAuth, useAuthModal } from '@/contexts';
import { useClient, useScreenSize } from '@/hooks';
import { trackGenericEvent, trackGenericPage, trackLandingPageView } from '@/utils/analytics';
import React, { FunctionComponent, useEffect } from 'react';
import { Auth0RolesE } from '@/types/cyclone/models';
import { useMutation } from '@tanstack/react-query';
import { EmptyObject } from 'react-hook-form';
import { ErrorI } from '@/types/cyclone/requests';
import { ResponsiveContainer } from '@/components/ResponsiveContainer';
import {
  CTA,
  ClientExperience,
  FAQs,
  Hero,
  Integrations,
  Pricing,
  Professionals,
  StoreSteps,
  Support,
  Tools,
  Why,
  Workshops
} from './components';

declare global {
  interface Window {
    Intercom?: Intercom;
    intercomSettings?: any;
  }
}

const FLASH_URL = process.env.NEXT_PUBLIC_FLASH_URL || 'https://pro.agora.red';

export type Intercom = (action: string, ...args: any[]) => void;

const Home: FunctionComponent = () => {
  const [loadIntercom, setLoadIntercom] = React.useState(false);
  const { isMobile } = useScreenSize();
  const { isAuthenticated, session } = useAuth();
  const { showAuthModal } = useAuthModal();
  const { client } = useClient();

  const mutation = useMutation<EmptyObject, ErrorI>(
    () => client<EmptyObject>(`vendors`, 'POST', { isAuthRequired: true }),
    {
      onSuccess: () => {
        window.location.href = FLASH_URL;
      }
    }
  );

  useEffect(() => {
    trackLandingPageView();
  }, []);

  const convertToVendor = (section: string) => {
    trackGenericEvent('Button Landing Page Start Now Clicked', { section });

    if (isAuthenticated) {
      if (session?.role === Auth0RolesE.VENDOR) {
        window.location.href = FLASH_URL;
      } else {
        mutation.mutate();
      }
    } else {
      showAuthModal('signup', true);
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.body.scrollHeight;

    const scrolledPercentage = (scrollPosition / (bodyHeight - windowHeight)) * 100;

    if (scrolledPercentage > 50) {
      setLoadIntercom(true);
    }
  };

  useEffect(() => {
    trackGenericPage('Landing');
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (loadIntercom) {
      // Place your Intercom App ID here
      const YOUR_INTERCOM_APP_ID = 'lt3sf6pd';

      window.intercomSettings = {
        app_id: YOUR_INTERCOM_APP_ID
      };

      // Use an immediately-invoked function expression
      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i: any = function () {
            // eslint-disable-next-line prefer-rest-params
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args: any) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + YOUR_INTERCOM_APP_ID;
            const x = d.getElementsByTagName('script')[0];
            x.parentNode?.insertBefore(s, x);
          };
          l();
        }
      })();

      return () => {
        // This will run when the component is unmounted
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Intercom('shutdown');
      };
    }
  }, [loadIntercom]);

  return (
    <>
      <ResponsiveContainer className="text-black z-10 overflow-hidden">
        <Hero onCTA={convertToVendor} />
        <Why />
        <Tools />
        <StoreSteps onCTA={convertToVendor} />
        <ClientExperience />
        <Professionals />
        <Pricing onCTA={convertToVendor} />
        <Workshops onCTA={convertToVendor} />
        <Integrations />
        <Support />
        <FAQs />
        {!isMobile && <CTA onCTA={convertToVendor} />}
      </ResponsiveContainer>
      {isMobile && <CTA onCTA={convertToVendor} />}
    </>
  );
};

export default Home;
