import { ImageProps } from 'next/legacy/image';

interface ImagePropsExtended {
  objectFit: ImageProps['objectFit'];
  objectPosition: ImageProps['objectPosition'];
  placeholder: ImageProps['placeholder'];
  src: ImageProps['src'];
  blurDataURL: ImageProps['blurDataURL'];
}

export const commonImageProps = (coverUrl: string): ImagePropsExtended => ({
  objectFit: 'cover',
  objectPosition: 'center',
  src: `${coverUrl}.webp`,
  placeholder: 'blur',
  blurDataURL: `${coverUrl}_blur.webp`
});
