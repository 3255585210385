export type integrationType = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

export const icons: integrationType[] = [
  {
    src: `${IMAGE_CDN_URL}/landing/mercado_pago.png`,
    alt: 'agora-mercado-pago',
    width: 120,
    height: 68
  },
  {
    src: `${IMAGE_CDN_URL}/landing/google_calendar.png`,
    alt: 'agora-google-calendar',
    width: 120,
    height: 120
  },
  {
    src: `${IMAGE_CDN_URL}/landing/google_maps.png`,
    alt: 'agora-google-maps',
    width: 150,
    height: 120
  },
  {
    src: `${IMAGE_CDN_URL}/landing/whatsapp.png`,
    alt: 'agora-whatsapp',
    width: 140,
    height: 120
  },
  {
    src: `${IMAGE_CDN_URL}/landing/zoom.png`,
    alt: 'agora-zoom',
    width: 120,
    height: 120
  },
  {
    src: `${IMAGE_CDN_URL}/landing/stripe.png`,
    alt: 'agora-stripe',
    width: 72,
    height: 120
  }
];
