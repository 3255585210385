import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

type TextChangerProps = {
  words: string[];
  className?: string;
  style?: React.CSSProperties;
  gradient?: boolean;
  from?: string;
  to?: string;
};

const TextChanger: React.FC<TextChangerProps> = React.memo(
  ({ words, className, style, gradient, from, to }) => {
    const [wordIndex, setWordIndex] = useState(0);
    const controls = useAnimation();
    const containerRef = useRef<HTMLSpanElement>(null);

    const changeWord = async () => {
      await controls.start({ opacity: 0, transition: { duration: 0.3 } });
      setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      await controls.start({ opacity: 1, transition: { duration: 0.3 } });
    };

    useEffect(() => {
      const interval = setInterval(changeWord, 2000);
      return () => clearInterval(interval);
    }, []);

    const textStyle = gradient
      ? {
          background: `linear-gradient(90deg, ${from || '#000'}, ${to || '#fff'})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          ...style
        }
      : style;

    return (
      <span
        ref={containerRef}
        className={className}
        style={{ display: 'inline-block', position: 'relative', ...style }}
      >
        <motion.span
          initial={{ opacity: 1 }}
          animate={controls}
          style={{ position: 'absolute', left: 0, top: 0, ...textStyle }}
        >
          {words[wordIndex]}
        </motion.span>
        <span style={{ visibility: 'hidden' }}>{words[0]}</span>
      </span>
    );
  }
);

export default TextChanger;
